import React, { useState, useEffect } from 'react';
// import { useTimer } from 'react-timer-hook';
import TimerStyled from './TimerStyled';

const useCustomTimer = (targetTime, onTimeUp) => {
    const [time, setTime] = useState(targetTime);

    // Convert total time in seconds into days, hours, minutes, and seconds
    const calculateTime = (seconds) => {
        const days = Math.floor(seconds / (24 * 60 * 60));
        const hours = Math.floor((seconds % (24 * 60 * 60)) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return { days, hours, minutes, seconds: remainingSeconds };
    };

    // useEffect to handle the countdown and call the callback when time reaches 0
    useEffect(() => {
        if (time <= 0) return;

        const interval = setInterval(() => {
            setTime((prevTime) => {
                const newTime = prevTime - 1;
                if (newTime <= 0 && onTimeUp) {
                    onTimeUp(); // Execute the callback when time is up
                }
                return newTime;
            });
        }, 1000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [time, onTimeUp]);

    // Get time breakdown (days, hours, minutes, seconds)
    const { days, hours, minutes, seconds } = calculateTime(time);

    // Return the current time and its breakdown
    return { days, hours, minutes, seconds };

};



const Countdown = ({ expiryTimestamp, onExpire, theme }) => {
    // const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp, onExpire });
    const { seconds, minutes, hours, days } = useCustomTimer(Math.round(expiryTimestamp / 1000), onExpire);
    return (
        <TimerStyled type='COUNTDOWN' theme={theme} seconds={seconds} minutes={minutes} hours={hours} days={days} />
    );
};

export default Countdown;